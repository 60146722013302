











































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { BusinessTitleBanner } from '~/components/widgets';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import buildMeta from '~/libraries/meta';

@Component({
  components: {
    BusinessTitleBanner,
    FontAwesomeIcon
  },
  computed: {
    faArrowRight() {
      return faArrowRight;
    }
  }
})
export default class EpikPage extends Vue {
  en_link =
    'https://blog.beanfield.com/beanfield-metroconnect-acquires-epik-networks-canada/';
  head() {
    return buildMeta({
      title: this.$t('openface:meta-title'),
      description: this.$t('epik:meta-description'),
      canonical: 'https://www.beanfield.com/epiknetworks',
      socialImage: 'welcome-to-beanfield',
      imageType: 'image/jpeg'
    });
  }
  layout() {
    return 'acquisition';
  }
}
